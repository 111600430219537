import React from 'react';

import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

export const STATUS_INDICATION_LIST = {
  SEARCH: {
    A: { name: 'Available', color: '#6bc950', hover: 'Available' },
    M: { name: 'On Memo', color: '#307bea', hover: 'Memo' },
    H: { name: 'Hold', color: '#db1c1c', hover: 'Hold' },
    B: { name: 'BID', color: '#1cdbc1', hover: 'BID' },
    U: { name: 'Upcoming', color: 'orange', hover: 'Upcoming' },
    E: { name: 'Special Stone', color: '#000000', hover: 'Special Stone' },
    SW: { name: 'Show ', color: '#fbb80f', hover: 'Show ' },
  },
  HOLD: {
    //H,N,I,CH,EH,SH,CX,EX,SX
    H: { name: 'Hold', color: '#c1372b', salesperson: true, date: true, time: true },
    // H1: { name: 'Sales Rep. Hold', color: '#c1372b', salesperson: true, date: true, time: true },
    // N: { name: 'Night', color: '#cfcfcf', salesperson: true, date: true, time: true },
    // I: { name: '48 Hours Hold', color: '#ff0000', salesperson: true, date: true, time: true },
    // CH: { name: 'Cons. Hold', color: '#ff0000', salesperson: true, date: true, time: true },
    // EH: { name: 'Ext. Hold', color: '#ff0000', salesperson: true, date: true, time: true },
    // SH: { name: 'Show Hold', color: '#f477e2', salesperson: true, date: true, time: true },
    // CX: { name: 'Cons. Int. Hold', color: '#ff0000', salesperson: true, date: true, time: true },
    // EX: { name: 'Ext. Int. Hold', color: '#ff0000', salesperson: true, date: true, time: true },
    // SX: { name: 'Show Int. Hold', color: '#f477e2', salesperson: true, date: true, time: true },
  },
  BNR2: {
    //B,CB,EB,SB J,CJ,EJ,SJ
    B: { name: 'Blue Nile', color: '#656566', hover: 'Bluenile' },
    CB: { name: 'Cons. Blue Nile', color: '#808080', hover: 'Consignment Blue Nile' },
    EB: { name: 'Ext. Blue Nile', color: '#808080', hover: 'External Blue Nile' },
    SB: { name: 'Show Blue Nile', color: '#f477e2', hover: 'Show Blue Nile' },
    J: { name: 'James Allen', color: '#fb9999', date: true, time: true, pre: 'James Allen' },
    CJ: { name: 'Cons. James Allen', color: '#ffc0cb', hover: 'Consignment James Allen' },
    EJ: { name: 'Ext. James Allen', color: '#ffc0cb', hover: 'External James Allen' },
    SJ: { name: 'Show James Allen', color: '#f477e2', hover: 'Show James Allen' },
  },
  CONSIGNMENT: {
    // M,C1,C2,C3,C4,C5,CM,EM,SM,T
    M: { name: 'Memo', color: '#cc9a2b', salesperson: true, date: true, time: true },
    C1: { name: 'C1', color: '#2b2f6a', salesperson: true, date: true, time: true },
    C2: { name: 'C2', color: '#6453af', salesperson: true, date: true, time: true },
    C3: { name: 'C3', color: '#6051ac', company: true, salesperson: true, date: true, location: true },
    C4: { name: 'C4', color: '#163272', company: true, salesperson: true, date: true, location: true },
    C5: { name: 'C5', color: '#5f51ac', company: true, salesperson: true, date: true, location: true },
    CM: { name: 'Cons. Memo', color: '#445fcc', salesperson: true, date: true, location: true },
    EM: { name: 'Ext. Memo', color: '#871f1b', salesperson: true, date: true, time: true },
    SM: { name: 'Show Memo', color: '#f477e2', salesperson: true, date: true, time: true },
    T: { name: 'Transit', color: '#4886fb', location: true, pre: 'Transit' },
  },
  OFFICE: {
    O: { name: 'Off. View', color: '#ffff00', salesperson: true, date: true, time: true },
  },
  ECSE: {
    E: { name: 'External', color: '#000000', hover: 'External' },
    EM: { name: 'Ext. Memo', color: '#871f1b', salesperson: true, date: true, time: true },
    EH: { name: 'Ext. Hold', color: '#ff0000', salesperson: true, date: true, time: true },
    SM: { name: 'Show Memo', color: '#f477e2', salesperson: true, date: true, time: true },
    SH: { name: 'Show Hold', color: '#f477e2', salesperson: true, date: true, time: true },
  },
  ECSC: {
    C: { name: 'Cons.', color: '#000000', hover: 'Consignment' },
    CM: { name: 'Cons. Memo', color: '#445fcc', salesperson: true, date: true, location: true },
    CH: { name: 'Cons. Hold', color: '#ff0000', salesperson: true, date: true, time: true },
    SM: { name: 'Show Memo', color: '#f477e2', salesperson: true, date: true, time: true },
    SH: { name: 'Show Hold', color: '#f477e2', salesperson: true, date: true, time: true },
  },
  ECSS: {
    S: { name: 'Show', color: '#e05da9', hover: 'Show' },
    SM: { name: 'Show Memo', color: '#f477e2', salesperson: true, date: true, time: true },
    SH: { name: 'Show Hold', color: '#f477e2', salesperson: true, date: true, time: true },
  },
  NEWARRIVAL: {
    A: { name: 'Available', color: '#383838', hover: 'Available' },
    O: { name: 'Off. View', color: '#ffff00', salesperson: true, date: true, time: true },
    Y: { name: 'Smart i', color: '#ee82ee', hover: 'Smart Eye' },
    Z: { name: 'Gemprint', color: '#ee82ee', hover: 'Gemprint' },
  },
};

export const STATUS_INDICATION = STATUS_INDICATION_LIST.SEARCH;

const Status = React.memo((props) => {
  const status = STATUS_INDICATION?.[props?.status] ?? STATUS_INDICATION_LIST?.[props?.page]?.[props?.status];

  return (
    <Tooltip title={props?.sStsDesc}>
      <div className="showSatusBlock">
        <div className="SelectcolorWiseList" style={{ backgroundColor: status?.color }} />
        <div className="status" style={{ margin: 'auto' }}>
          <span>{status?.name ?? props?.status ?? '-'}</span>
        </div>
      </div>
    </Tooltip>
  );
});

Status.propTypes = {
  status: PropTypes.string,
  sStsDesc: PropTypes.string,
};

export default Status;
