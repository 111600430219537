import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Drawer, Dropdown, Modal } from 'antd';

// import { ExportExcel } from 'components/Inventory/ExportExcel';
import { map, uniq, compact } from 'lodash';

import BottomAction from 'containers/BottomAction';

import CircularProgress from 'components/CircularProgress';
import FinalCalculations from 'components/DiamondListing/FinalCalculations';
import { getNewStatus } from 'components/Inventory/inventoryFunctions';
import Pagination from 'components/List/Pagination';
import OrderApprove from 'components/Transaction/Order/OrderApprove';

import { downloadDiamondExcel, downloadPdfFile, handleBidDelete } from 'services/DiamondApi';
import { showNotification } from 'services/NotificationService';
import Storage from 'services/storage';
import UtilService from 'services/util';

import { useBoolean, useCompositeState } from 'util/hooks';
import { i18n } from 'util/i18n';
import { Notify } from 'util/notify';
import { isArray, isEmpty, isFunction, queryStringToObject } from 'util/utils';

import { TRACK_TYPES, USERS_TYPE } from 'constants/Common';
import CommonApi from 'constants/CommonApi';
import { PREFIX_URL_WITH_SLASH } from 'constants/CommonUrl';
import Messages from 'constants/Messages';
import {
  getPermittedInsertListFirst,
  MODULES,
  MAINMODULE,
  getPermittedInsertList,
  downloadPermit,
  printPermit,
} from 'constants/permission';

import { DiamondActions } from 'appRedux/reducers/diamond';

import BottomCompareStone, { BottomCompareStoneButton, CompareStoneTable } from './Actions/BottomCompareStone';
import BottomInstruct from './Actions/BottomInstruct';
import DownloadAction from './Actions/DownloadAction';
import SendEmailPopup from './Actions/SendEmailPopup';
import ShareAction from './Actions/ShareAction';
import BidPopup from './BidPopup';
import CommentPopup from './CommentPopup';
import { checkClientName, onUpload } from './CommonPopupFunction';
import EnquiryClosePopup from './EnquiryClosePopup';
import HoldPopup from './HoldPopup';
import MemoPopup from './MemoPopup';
import OfficeSidebar from './OfficeSidebar';
import QuoteDayPopup from './QuoteDayPopup';
import SingleStonePlaceOrderPopup from './SingleStonePlaceOrderPopup';
// import OfferPopup from './OfferPopup';
// import BottomMailAction from './Actions/BottomMailAction';

// import { handlePrint } from './CommonPopupFunction';

// import { UserTerms } from 'services/CommonApi';
// import { downloadPdfFile } from 'services/DiamondApi';

import UtilApprove from './UtilApprove';

import OfferPagePopup from '../../routes/Offer';

const isAction = (main, sub, model) => !isEmpty(getPermittedInsertListFirst(main, sub, model));

const DiamondListingAction = (props) => {
  const { unCheckedDiamond } = props;
  const deselectAllStoneRef = React.useRef();

  const actionPermission = React.useMemo(
    () => ({
      quote: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.QUOTE.NAME),
      hold: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.HOLD.NAME),
      memo: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.MEMO.NAME),
      confirm: isAction(MAINMODULE.TRANSACTION, MODULES.TRANSACTION.CONFIRM_STONE.NAME),
      quoteDay: isAction(MAINMODULE.INVENTORY, null, MODULES.INVENTORY.SINGLE_STONE.MODULE.QUOTEDAY),
      searchPermit: getPermittedInsertList(MAINMODULE.INVENTORY, MODULES.INVENTORY.SINGLE_STONE.NAME).includes(
        MODULES.INVENTORY.SINGLE_STONE.MODULE.SEARCH,
      ),
      cart: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.CART),
      watch: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.WATCHLIST),
      notes: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.NOTE),
      office: isAction(MAINMODULE.CLIENT, null, MODULES.CLIENT.TRANSACTION.MODULE.OFFICE_VIEW),
      downloadExcel: downloadPermit(),
      print: printPermit(),
    }),
    [],
  );
  const [compareStones, setCompareStones] = useBoolean(false);
  const [loader, setLoader] = useState(false);

  const [state, setState] = useCompositeState({ singleStonePalaceOrder: false, commentPopup: false, tab: '' });
  const [userTerms] = useState(null);
  const [officeDrawer, setOfficeDrawer] = useState(false);
  const [visibleType, setVisibleType] = useState('');
  const [uploadFile, setuploadFile] = useState(true);

  const chk = useSelector((state) => state?.diamondData?.selectedRows?.[props?.currentType]);
  const checked = React.useMemo(() => props?.checkedData ?? chk ?? [], [chk, props?.checkedData]);

  const dispatch = useDispatch();
  const clearAllSelectedRows = useCallback(() => {
    if (isFunction(props.clearAllSelectedRows)) props.clearAllSelectedRows();
    dispatch(DiamondActions.resetSelectedRows());
  }, [dispatch, props]);

  const toggleMemo = React.useCallback(() => setState({ memoPopup: !state.memoPopup }), [state.memoPopup, setState]);
  const toggleHold = React.useCallback(() => setState({ holdPopup: !state.holdPopup }), [state.holdPopup, setState]);
  const toggleOfficeDrawer = React.useCallback(() => setOfficeDrawer((value) => !value), []);
  const toggleOffer = React.useCallback(
    () => setState({ OfferPopup: !state.OfferPopup }),
    [state.OfferPopup, setState],
  );
  const toggleApprove = React.useCallback(
    () => setState({ approvePopup: !state.approvePopup }),
    [state.approvePopup, setState],
  );
  const toggleFinalCalculation = React.useCallback(() => {
    setState({ finalCalculation: !state.finalCalculation });
  }, [state.finalCalculation, setState]);
  const toggleQuoteDay = React.useCallback(() => setState({ quoteDay: !state.quoteDay }), [state.quoteDay, setState]);
  const toggleBid = React.useCallback(() => {
    setState({ bid: !state.bid });
    if (state.bid) clearAllSelectedRows();
  }, [setState, state.bid, clearAllSelectedRows]);
  const toggleCompareEmail = React.useCallback(
    () => setState({ compareEmail: !state.compareEmail }),
    [state.compareEmail, setState],
  );

  const handleDeleteBid = React.useCallback(() => {
    handleBidDelete(
      checked?.map((d) => d?.id),
      (flag) => {
        props.fetch();
        props.clearAll();
        // if (flag) props.setCount(!props.deleteReminder.count);
      },
    );
  });

  const toggleTab = React.useCallback(
    (tab) => {
      switch (tab) {
        case 'memo':
          toggleMemo();
          break;

        case 'hold':
          toggleHold();
          break;

        case 'offer':
          toggleOffer();
          break;

        case 'finalCalculation':
          toggleFinalCalculation();
          break;
        case 'quoteDay':
          toggleQuoteDay();
          break;
        case 'bid':
          toggleBid();
          break;
        default:
          break;
      }
    },
    [toggleBid, toggleFinalCalculation, toggleHold, toggleMemo, toggleOffer, toggleQuoteDay],
  );

  const validateClient = React.useCallback(() => {
    if (!isEmpty(props.client)) return true;
    Notify.error({ message: Messages.Error.Required.Company });
    return false;
  }, [props.client]);

  const validateCheckedItems = React.useCallback(() => {
    if (!isEmpty(checked)) return true;
    Notify.error({ message: Messages.Error.Required.Stones });
    return false;
  }, [checked]);

  const checkDiamondStatus = React.useCallback(
    (tab) => {
      const diamondStatus = checked.map((d) => d.wSts);

      if (diamondStatus.includes('M') || diamondStatus.includes('H')) {
        if (!['hold', 'memo'].includes(tab)) {
          if (validateClient()) {
            const checkecId = checked?.map((d) => d?.id);
            const users = props?.inBlockDiamonds?.length
              ? compact(
                  uniq(
                    props.inBlockDiamonds.map((d) => {
                      if (checkecId.includes(d?.blockDetails?.[0]?.diamond)) return d?.blockDetails?.[0]?.user?.id;
                    }),
                  ),
                )
              : [];

            if (users?.includes(props?.client?.user?.id) && users?.length == 1) return true;
            Notify.error({ message: Messages.Error.Required.MemoAndHoldStone });
          }
        } else {
          Notify.error({ message: Messages.Error.Required.MemoAndHoldStone });
        }
      } else {
        return true;
      }
      return false;
    },
    [checked, props?.client?.user?.id, props.inBlockDiamonds, validateClient],
  );

  const popupShow = React.useCallback(
    (tab) => {
      if (tab === 'offer') {
        const itemsAreValid = validateCheckedItems();
        if (itemsAreValid) {
          if (props.inOffer) {
            const clients = uniq(checked?.map((x) => (x.user ? x.user.id : null))?.filter((el) => el && el));
            if (clients.length > 1) {
              Notify.error({ message: 'Please select diamonds for only one company.' });
            } else toggleTab(tab);
          } else if (validateClient()) {
            toggleTab(tab);
          }
        }
        return;
      }
      if (
        ['enquiry', 'comment', 'finalCalculation', 'bid'].includes(tab) &&
        validateCheckedItems() &&
        validateClient()
      ) {
        // const isClientValid = true;
        if (tab == 'enquiry') setState({ singleStonePalaceOrder: !state.singleStonePalaceOrder, tab });
        if (tab === 'comment') setState({ commentPopup: !state.commentPopup });

        if (['finalCalculation', 'bid'].includes(tab)) toggleTab(tab);
        return;
      }

      if (
        [
          'hold',
          'memo',
          'order',
          'offer',
          'approve',
          'quoteApprove',
          'office',
          'orderApprove',
          'quoteDay',
          'showStone',
          'emailStock',
          'emailPic',
          'holdMemoApprove',
        ].includes(tab) &&
        validateCheckedItems()
      ) {
        if (['offer', 'quoteDay'].includes(tab)) {
          const fm = checked.filter((el) => getNewStatus(el) && el.crt <= 3);
          if (fm.length !== 0) {
            const idList = checked.map((x) => x.vStnId).join(', ');
            const article = fm.length === 1 ? ' is' : ' are';
            Notify.error({ message: `Packet No. ${idList} ${article}  not eligible for ${i18n.t('title.offer')}` });
            return;
          }
        }
        if (['hold', 'memo', 'offer', 'quoteDay'].includes(tab) && checkDiamondStatus(tab)) {
          toggleTab(tab);
        }
        if (['emailStock', 'emailPic'].includes(tab)) {
          setState({ tab });
          toggleCompareEmail();
        }
        if (['order', 'quoteApprove', 'orderApprove', 'showStone', 'holdMemoApprove'].includes(tab)) {
          const isStateUpdate = ['quoteApprove', 'orderApprove', 'holdMemoApprove'].includes(tab)
            ? checkClientName(checked)
            : true;
          const isDiamondStatusCheck = ['order', 'showStone'].includes(tab) ? checkDiamondStatus(tab) : true;
          if (isStateUpdate && isDiamondStatusCheck) {
            setState({ singleStonePalaceOrder: !state.singleStonePalaceOrder, tab });
          }
        }
        // if (tab == 'approve') {
        //   toggleApprove();
        // }
        if (tab == 'office') {
          toggleOfficeDrawer();
        }
        return;
      }
    },
    [
      checkDiamondStatus,
      checked,
      props.inOffer,
      setState,
      state.commentPopup,
      state.singleStonePalaceOrder,
      toggleCompareEmail,
      toggleOfficeDrawer,
      toggleTab,
      validateCheckedItems,
      validateClient,
    ],
  );

  /**
   * Function used to insert track
   * @param {*} trackType
   * @param {*} checkedData
   */
  const handleInsertTrack = React.useCallback(
    async (trackType, checkedData) => {
      const isClientValid = validateClient();

      if (!isClientValid) return;

      const isDiamondValid = validateCheckedItems();
      if (!isDiamondValid) return;

      const diamonds =
        isArray(checkedData) &&
        checkedData.map((item) => ({
          diamond: item.id,
          trackPricePerCarat: item.ctPr,
          trackAmount: item.amt,
        }));
      setLoader((loader) => !loader);

      const [err, res] = await UtilService.callApiAsync({
        ...CommonApi.DiamondTrack.create,
        request: {
          trackType,
          diamonds,
          user: !isEmpty(props.client?.user?.id) ? props.client?.user?.id : props.client.id,
          userAccount: !isEmpty(props.client?.id) ? props.client?.id : props.client.id,
        },
      });
      if (!err && res?.code === 'OK') {
        if (props.fetch) props.fetch();
        clearAllSelectedRows();
        showNotification(res);
      }
      setLoader((loader) => !loader);
    },
    [clearAllSelectedRows, props, validateCheckedItems, validateClient],
  );

  const visibleTypeButtonHandler = React.useCallback(
    (type) => () => {
      if (isEmpty(checked)) return Notify.error({ message: `Please select stone(s).` });
      setVisibleType(type);
    },
    [checked],
  );

  const handlePrintClick = React.useCallback(() => {
    if (validateCheckedItems()) {
      downloadPdfFile(
        checked,
        props?.requestUrl,
        props?.seachFilterTag?.searchData ?? props?.filter ?? {},
        props?.tabPrintExcel,
        setLoader,
        props?.excelPDFTab,
      );
    }
    clearAllSelectedRows();
  }, [
    validateCheckedItems,
    checked,
    props?.requestUrl,
    props?.seachFilterTag?.searchData,
    props?.filter,
    props?.tabPrintExcel,
    props?.excelPDFTab,
    clearAllSelectedRows,
  ]);

  const visibleTypeModelClose = React.useCallback(() => setVisibleType(''), []);

  const modifySearch = React.useCallback(() => {
    const tabUrl = props.matchPair ? `couple-stone` : props.layOut ? `search-layout` : 'search';

    const queryString = queryStringToObject();
    props.history.push(`${PREFIX_URL_WITH_SLASH}/inventory/single-stone/${tabUrl}`, {
      searchKey: props?.activeFilterId ?? queryString.result,
      stoneIds: props?.stoneIds,
      customer: props?.client,
    });
  }, [props?.activeFilterId, props?.client, props.history, props?.stoneIds]);

  React.useEffect(() => {
    deselectAllStoneRef.current = () => {
      if (isFunction(unCheckedDiamond)) {
        void unCheckedDiamond?.();
      } else if (isFunction(clearAllSelectedRows)) {
        clearAllSelectedRows();
      }
    };
  }, [clearAllSelectedRows, unCheckedDiamond]);

  React.useEffect(() => {
    return () => {
      if (isFunction(deselectAllStoneRef.current)) deselectAllStoneRef.current();
    };
  }, []);

  const checkCompareStone = () => {
    if (checked.length < 2) return Notify.error({ message: Messages.Error.Required.Stones });

    return setCompareStones.true();
  };

  const popupOffer = React.useCallback(() => popupShow('offer'), [popupShow]);

  const handleBidWinner = React.useCallback(
    () => isFunction(props.declareBidWinner) && props.declareBidWinner(checked),
    [checked, props],
  );

  const actions = (
    <>
      <div className="d-flex">
        {props.guestExcelDownload && (
          <div className="d-flex listingAction">
            <BottomAction title="Download Excel" onClick={props.guestExcelDownload} />
          </div>
        )}
        {props.ActionFalse === false ||
        props.columnSetting ||
        props.isOfficeView ||
        props.todolist ||
        props.userPermission ||
        props.isInQuoteDayList ||
        props.inCheckPacket ? null : (
          <div className="d-flex listingAction">
            {props.isSearchTab && !window.location.search.includes('?similar') && actionPermission.searchPermit && (
              <BottomAction title="Modify Search" onClick={modifySearch} />
            )}
            {actionPermission.confirm && (
              <BottomAction title="Confirm Stone" hightlight onClick={() => popupShow('order')} />
            )}
            {actionPermission.hold && <BottomAction title="Hold" onClick={() => popupShow('hold')} {...props} />}
            {actionPermission.memo && <BottomAction title="Memo" onClick={() => popupShow('memo')} {...props} />}
            {Storage.get('user')?.type != USERS_TYPE.SELLER && actionPermission.quote && (
              <BottomAction title={`${i18n.t('title.offer')}`} hightlight onClick={() => popupShow('offer')} />
            )}
            {actionPermission.cart && (
              <BottomAction title="Cart" onClick={() => handleInsertTrack(TRACK_TYPES.Cart, checked)} />
            )}
            {/* <BottomAction title="Enquiry" onClick={() => popupShow('enquiry')} /> */}
            <BottomAction title="Final Calculation" hightlight onClick={() => popupShow('finalCalculation')} />
            {/* {!props.priceRevice && actionPermission.watch && (
              <BottomAction
                title="Watchlist"
                onClick={() => checkDiamondStatus() && handleInsertTrack(TRACK_TYPES.Watchlist, checked)}
              />
            )} */}
            {actionPermission.office && <BottomAction title="Office" onClick={() => popupShow('office')} />}
            {actionPermission.downloadExcel && (
              <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
            )}

            {/* <BottomCompareStone list={props?.checked} /> */}
            <Dropdown
              trigger={['click']}
              overlay={
                <div className="moreOptionDropdown">
                  <BottomCompareStoneButton onClick={checkCompareStone} />
                  <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />

                  {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
                  {!props.priceRevice && actionPermission.notes && (
                    <BottomAction title="Notes" onClick={() => popupShow('comment')} />
                  )}
                  {/* <BottomAction title="Reminder" /> */}
                  {/* {!props.priceRevice && <BottomAction title="Export Excel" onClick={onExportExcel} />} */}
                  {/* {!props.priceRevice && (
                    <BottomMailAction
                      list={checked}
                      client={props.client}
                      clearAllSelectedRows={clearAllSelectedRows}
                    />
                  )} */}
                  {!props.offerDay && !props.priceRevice && actionPermission.quoteDay && (
                    <BottomAction
                      title={`${i18n.t('title.offer')} Day`}
                      onClick={() => popupShow('quoteDay')}
                      {...props}
                    />
                  )}
                  {!props.priceRevice && <BottomAction title="Show Stone" onClick={() => popupShow('showStone')} />}
                </div>
              }
            >
              <a className="bottomStickyButton">
                <span>More</span>
              </a>
            </Dropdown>
          </div>
        )}
        {props.email && (
          <div className="d-flex listingAction">
            <BottomAction title="Reply" />
            <BottomAction title="Reply All" />
            <BottomAction title="Forward" />
          </div>
        )}
        {props?.declareBidWinner && (
          <div className="d-flex listingAction">
            <BottomAction title="Make Winner" onClick={handleBidWinner} />
          </div>
        )}
        {props.isInQuoteDayList && (
          <div className="d-flex listingAction">
            <BottomAction title="Delete" onClick={props.onDeleteClick} />
            {actionPermission.searchPermit && <BottomAction title="Modify Search" onClick={props.searchResult} />}
          </div>
        )}
        {props.isDeleteBid && (
          <div className="d-flex listingAction">
            <BottomAction title="Remove Bid" onClick={handleDeleteBid} />
          </div>
        )}
        {props.confimEnquiry && actionPermission.downloadExcel && <BottomAction title="Download Excel" />}
        {props.offerEnquiry && (
          <div className="d-flex">
            <BottomAction title="Approve" />
            <BottomAction title="Modify" to="/admaadmin/parcel-diamond/offer" />
            <BottomAction title="Rejected" />
          </div>
        )}
        {props.inUtil && (
          <div className="d-flex">
            <BottomAction
              title="Approve"
              onClick={() => {
                validateCheckedItems() && setState({ utilApprove: true });
              }}
            />
            <BottomAction
              title="Rejected"
              onClick={() => {
                validateCheckedItems() && setState({ utilReject: true });
              }}
            />
          </div>
        )}
        {(props.orderList || props.inOffer) && (
          <div className="d-flex listingAction">
            {/* {['approved', 'rejected'].includes(props.tab) ? null : (
              <>
                <BottomAction
                  title="Approve"
                  onClick={() => (props.orderList ? popupShow('orderApprove') : popupShow('quoteApprove'))}
                  checked={checked}
                />
                <BottomAction title="Reject" onClick={() => validateCheckedItems() && props.rejectClick()} />
              </>
            )} */}
            {/* {props.tab === 'rejected' && <BottomAction title="Approve" onClick={props.approveClick} />} */}
            {props.inOffer && ['COUNTER'].includes(props.tab) && (
              <>
                <BottomAction title="Approve" onClick={() => popupShow('quoteApprove')} />
                <BottomAction
                  title="Reject"
                  onClick={() => {
                    validateCheckedItems() && props.rejectClick();
                  }}
                />
              </>
            )}
            {props.inOffer && ['PENDING'].includes(props.tab) && (
              <>
                <BottomAction title="Approve" onClick={() => popupShow('quoteApprove')} />
                <BottomAction title="Modify" onClick={popupOffer} />
                <BottomAction
                  title="Reject"
                  onClick={() => {
                    validateCheckedItems() && props.rejectClick();
                  }}
                />
                {/* <BottomAction
                  title="Upload Excel"
                  onUpload={(e) => {
                    setuploadFile(false);
                    onUpload(e, (flag) => {
                      setuploadFile(true);
                      if (props.fetch && flag) props.fetch();
                    });
                  }}
                  uploadFile={
                    //props.client && !isEmpty(props.client)
                    // true
                    uploadFile
                  }
                  // onClick={validateClient}
                  client={props.client}
                /> */}
              </>
            )}
            <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />
            {actionPermission.downloadExcel && (
              <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
            )}
            {/* {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />} */}
          </div>
        )}
        {/* {(props.tranEnquiry || props.tranCart || props.tranWatchList) && (
          <div className="d-flex listingAction">
            <BottomAction title="Add To Confirm" />
            <BottomAction title="Add To Offer" link="/offer" />
          </div>
        )} */}
        {props.orderEnquiry && (
          <div className="d-flex">
            <BottomAction title="Approve" />
            <BottomAction title="Rejected" />
          </div>
        )}
        {props.appointemtEnquiry && (
          <div className="d-flex">
            <BottomAction title="Approve" />
            <BottomAction title="Rejected" />
          </div>
        )}
        {props.consignment && (
          <div className="d-flex">
            <BottomAction title="Edit" />
            <BottomAction title="Move To Confirm" link="/admaadmin/offer" />
            <BottomAction title="Return" />
          </div>
        )}
        {/* {props.tranOfficeView && (
          <div className="d-flex">
            <BottomAction title="Remove" onClick={props.removeHandle} />
          </div>
        )} */}
        {(props.unHold || props.memoReturn) && (
          <>
            {/**If memo Return need remove condition */}
            {props.unHold && <BottomAction title={props.unHold ? 'Release' : 'Memo Return'} onClick={props.unHoldFn} />}
            {actionPermission.confirm && props.confirmStone && (
              <BottomAction title="Confirm Stone" onClick={() => popupShow('holdMemoApprove')} />
            )}
            {props.approved && <BottomAction title="Approve" onClick={props?.approvedFn} />}
            {/* {props.rejected && <BottomAction title="Rejected" />} */}
          </>
        )}
        {/* {props.memoReturn && <BottomAction title="Memo Return" onClick={props.unHoldFn} />} */}
        {props.todolist && (
          <div className="d-flex">
            {props.approveTodo && <BottomAction title="Approve" />}
            {props.rejectTodo && <BottomAction title="Reject" />}
            {props.pendingTodo && <BottomAction title="Pending" />}
            {props.closeTodo && <BottomAction title="Close" />}
          </div>
        )}
        {props.isCancelEnquiry && (
          <>
            <BottomAction title="Hold" onClick={() => popupShow('hold')} {...props} />
            <BottomAction
              title="Close"
              onClick={() => {
                setState({ cancelEnquiry: true });
              }}
            />
          </>
        )}
        {props.ishold && (
          <>
            <BottomAction title="Hold" onClick={() => popupShow('hold')} {...props} />
          </>
        )}
        {props.inCheckPacket && (
          <>
            <BottomAction title="Get Stock" onClick={() => props.fetchData('stock')} />
            <BottomAction title="Get Sale" onClick={() => props.fetchData('sale')} />
            <BottomAction title="Get Hold" onClick={() => props.fetchData('hold')} />
          </>
        )}
        {props.isOfficeView && (
          <div className="listingAction d-flex">
            <BottomAction
              title="Delete"
              onClick={() => {
                const isDiamondValid = validateCheckedItems();
                isDiamondValid &&
                  Modal.confirm({
                    title: 'Are you sure delete this selected entries?',
                    okText: 'Delete',
                    onOk: props.removeHandle,
                  });
              }}
            />
            {actionPermission.memo && <BottomAction title="Memo" onClick={() => popupShow('memo')} {...props} />}
            <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />
            {actionPermission.downloadExcel && (
              <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
            )}
            {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
          </div>
        )}
        {props.isProformaList && (
          <div className="listingAction d-flex">
            <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />
            {actionPermission.downloadExcel && (
              <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
            )}
            {actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
          </div>
        )}
        {/* {props.watchlistType && (
          <div className="watchListPrice d-flex">
            <div className="watchListPriceItem d-flex align-items-center">
              <span className="priceRound increase" />
              <span>Price Increase</span>
            </div>
            <div className="watchListPriceItem d-flex align-items-center">
              <span className="priceRound decrease" />
              <span>Price Decrease</span>
            </div>
          </div>
        )} */}

        {props.instructions && <BottomInstruct list={checked} onSuccess={props.reload} />}
        {props.exportExcel ? <BottomAction title="Export Excel" onClick={props?.excelExportHandel} /> : ''}
        {props.modifySearch && <BottomAction title="Modify Search" onClick={modifySearch} />}
        {props.watchList && actionPermission.watch && (
          <BottomAction
            title="Watchlist"
            onClick={() => checkDiamondStatus() && handleInsertTrack(TRACK_TYPES.Watchlist, checked)}
          />
        )}
        {props.FinalCalculations && (
          <BottomAction title="Final Calculation" onClick={() => popupShow('finalCalculation')} />
        )}
        {props.bidStone && <BottomAction title="Bid Stone" onClick={() => popupShow('bid')} />}
        {props.compareStone && <BottomCompareStone list={checked} />}
        {props.removeStone && <BottomAction title="Remove Stone" onClick={props.onDeleteStone} />}
        {props.share && <BottomAction title="Share" onClick={visibleTypeButtonHandler('share')} />}
        {props.download && actionPermission.downloadExcel && (
          <BottomAction title="Download" onClick={visibleTypeButtonHandler('download')} />
        )}
        {props.printList && actionPermission.print && <BottomAction title="Print List" onClick={handlePrintClick} />}
        {Storage.get('user')?.type != USERS_TYPE.SELLER && props.delete && (
          <BottomAction title="Delete Order" onClick={props.deleteOrder} />
        )}
        {/* {props.mailList && (
          <BottomMailAction list={checked} client={props.client} clearAllSelectedRows={clearAllSelectedRows} />
        )} */}
        {props.emailStock && <BottomAction title="Email Stock" onClick={() => popupShow('emailStock')} />}
        {props.emailPic && <BottomAction title="Email Pic" onClick={() => popupShow('emailPic')} />}
        {props.save && <BottomAction title="Save" onClick={props.onSave} />}
        {props.report && <BottomAction title="Report" onClick={props.onSave} />}
      </div>

      <div className="d-flex">{isFunction(props.onPaginationChange) && <Pagination {...props} />}</div>
    </>
  );

  return (
    <>
      {loader && <CircularProgress className="fixLoader" />}
      {props.noContainer ? (
        actions
      ) : (
        <div
          className={`botoomStickyBar diamondListingAction
          ${(props.columnSetting || props.userPermission) && 'j-flex-start'}
           ${
             props.ActionFalse === false &&
             !props.guestExcelDownload &&
             !props.tranCart &&
             !props.declareBidWinner &&
             !props.inCheckPacket &&
             !props.tranEnquiry &&
             !props.watchlistType &&
             !props.tranOfficeView &&
             !props.orderList &&
             !props.inOffer &&
             !props.exportExcel &&
             !props.isCompare
               ? //  'justify-content-center'
                 ''
               : ''
           }`}
        >
          {actions}
        </div>
      )}
      <CompareStoneTable isOpen={compareStones} list={checked} handleClose={setCompareStones.false} />
      <Drawer
        onClose={toggleOfficeDrawer}
        visible={officeDrawer}
        wrapClassName="diamondDetailPopup xl-size OfficeViewPopup"
        destroyOnClose
      >
        {officeDrawer && (
          <OfficeSidebar
            visible={officeDrawer}
            onClose={toggleOfficeDrawer}
            checked={checked}
            unCheckedDiamond={props.unCheckedDiamond}
            client={props.client}
          />
        )}
      </Drawer>
      {/* <Drawer
        onClose={toggleOffer}
        visible={state.OfferPopup}
        wrapClassName="diamondListinSidebar xl-size"
        columns={props.columns}
        destroyOnClose
      >
        <OfferPopup
          onClose={toggleOffer}
          visible={state.OfferPopup}
          checked={checked}
          client={props.client}
        />
      </Drawer> */}
      {state.OfferPopup && (
        <OfferPagePopup
          checked={checked}
          inOffer={props.inOffer}
          onClose={() => setState({ OfferPopup: false })}
          fetch={props.fetch}
          tracks={props.tracks}
          client={!props.inOffer ? props.client : checked && checked.length ? checked[0].user : {}}
        />
      )}
      <Drawer
        onClose={toggleApprove}
        visible={state.approvePopup}
        wrapClassName="diamondListinSidebar lg-size"
        destroyOnClose
      >
        {state.approvePopup && <OrderApprove {...props} visible={state.approvePopup} checked={checked} />}
      </Drawer>

      <Drawer
        onClose={() => setState({ singleStonePalaceOrder: false, finalCalculation: false })}
        visible={state.singleStonePalaceOrder}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {state.singleStonePalaceOrder && (
          <SingleStonePlaceOrderPopup
            {...props}
            checked={checked}
            tab={state.tab}
            visible={state.singleStonePalaceOrder}
            onClose={(e) => {
              setState({ singleStonePalaceOrder: false, finalCalculation: false });
              if (e && props.fetch) props.fetch();
            }}
            handleInsertTrack={(data) => handleInsertTrack(TRACK_TYPES.Enquiry, data)}
            removeDiamond={props.removeDiamond}
            approveClick={(loadingFn, checkedData, request) =>
              props.approveClick(
                (loader) => {
                  loadingFn(loader);
                  if (!loader) setState({ singleStonePalaceOrder: !state.singleStonePalaceOrder });
                },
                checkedData,
                request,
              )
            }
            loadingFn={props.loadingFn}
          />
        )}
      </Drawer>
      <Drawer
        onClose={() => setState({ commentPopup: !state.commentPopup })}
        visible={state.commentPopup}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {state.commentPopup && (
          <CommentPopup
            {...props}
            visible={state.commentPopup}
            checked={checked}
            unCheckedDiamond={props.unCheckedDiamond}
            onClose={() => setState({ commentPopup: !state.commentPopup })}
          />
        )}
      </Drawer>
      {state.holdPopup && (
        <HoldPopup
          visible={state.holdPopup}
          handleCancel={toggleHold}
          {...props}
          checked={checked}
          clearAllSelectedRows={clearAllSelectedRows}
          userTerms={userTerms}
        />
      )}

      <Drawer
        onClose={toggleBid}
        visible={state.bid}
        wrapClassName="diamondListinSidebar offerPopup xl-size"
        destroyOnClose
      >
        {state.bid && <BidPopup {...props} visible={state.bid} checked={checked} onClose={toggleBid} />}
      </Drawer>
      {state.cancelEnquiry && (
        <EnquiryClosePopup
          checked={checked}
          fetch={props.fetch}
          clearAllSelectedRows={clearAllSelectedRows}
          onCancel={() => setState({ cancelEnquiry: false })}
        />
      )}
      {state.memoPopup && (
        <MemoPopup
          visible={state.memoPopup}
          handleCancel={toggleMemo}
          userTerms={userTerms}
          {...props}
          checked={checked}
          clearAllSelectedRows={clearAllSelectedRows}
        />
      )}
      {(state.utilApprove || state.utilReject) && (
        <UtilApprove
          visible={state.utilApprove || state.utilReject}
          handleCancel={() => setState({ utilApprove: false, utilReject: false })}
          checked={checked}
          actionName={state.utilApprove ? 'approve' : 'reject'}
        />
      )}
      {visibleType === 'share' && (
        <ShareAction {...props} visible={visibleType === 'share'} checked={checked} onClose={visibleTypeModelClose} />
      )}
      {Boolean(state.compareEmail) && (
        <SendEmailPopup
          visible={Boolean(state.compareEmail)}
          onCancel={toggleCompareEmail}
          ids={map(checked, 'id')}
          img={state.tab === 'emailPic'}
          isStock={state.tab === 'emailStock'}
        />
      )}
      {Boolean(state.quoteDay) && (
        <QuoteDayPopup
          visible={Boolean(state.quoteDay)}
          quoteDay={state.quoteDay}
          handleCancel={toggleQuoteDay}
          {...props}
          checked={checked}
        />
      )}
      {visibleType === 'download' && (
        <DownloadAction
          {...props}
          downloadAllDiamond={() => {
            downloadDiamondExcel(
              checked,
              props.requestUrl,
              props.seachFilterTag ? props.seachFilterTag.searchData : props.filter ? props.filter : {},
              props?.tabPrintExcel,
              setLoader,
              props?.excelPDFTab, //Set new Requirement
            );
          }}
          visible={visibleType === 'download' && Boolean(actionPermission.downloadExcel)}
          checked={checked}
          onClose={visibleTypeModelClose}
        />
      )}

      <Drawer
        FinalCalculation
        onClose={toggleFinalCalculation}
        visible={state.finalCalculation}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {state.finalCalculation && (
          <FinalCalculations
            checked={checked}
            client={props.client}
            {...props}
            onClose={() => toggleFinalCalculation}
            onConfirmStone={() => popupShow('order')}
            isShowConfirmStone={props.isShowConfirmStone}
            checkDiamondStatus={checkDiamondStatus}
          />
        )}
      </Drawer>
    </>
  );
};

export default React.memo(withRouter(DiamondListingAction));
