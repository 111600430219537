export const PREFIX_URL = 'uniquediamax';
export const PREFIX_URL_WITH_SLASH = `/${PREFIX_URL}`;
export const MEDIA_URL = 'https://s3.ap-south-1.amazonaws.com/finestargroup';

let ALL_COMMON_URL = {};
const BACKEND_URL =
  // "http://192.168.2.39:8094"
  process.env.REACT_APP_ENV === 'production' ? 'https://api.unique.diamonds' : 'https://betapi.unique.diamonds';

if (process.env.REACT_APP_ENV === 'production') {
  //Don't change this value
  ALL_COMMON_URL = {
    BASE_URL: BACKEND_URL,
    BASE_LINK: '',
    BASE_URL_FILE_UPLOAD: '',
    BASE_SYNC_URL: BACKEND_URL,
    EXCELFILE_URL: BACKEND_URL + '/data',
    FRONT_SITE_URL: 'https://client.unique.diamonds',
  };
} else {
  ALL_COMMON_URL = {
    BASE_URL: BACKEND_URL,
    BASE_LINK: '',
    BASE_URL_FILE_UPLOAD: BACKEND_URL,
    BASE_SYNC_URL: BACKEND_URL,
    EXCELFILE_URL: BACKEND_URL + '/data',
    FRONT_SITE_URL: 'https://beta.unique.diamonds',
  };
}

export const COMMON_URL = ALL_COMMON_URL;
